import React, { useRef, useState, useEffect } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import PageInfo from "../components/PageInfo";
import { StaticImage } from "gatsby-plugin-image";
import ContactForm from "../components/contact";
import { Link } from "gatsby";

const pageData = {
  header: "Salon",
  mainTitle: "JB multimedia",
  subtitle: "Nasz Salon stacjonarny",
};

const ShowroomsPage = () => {
  const [active, setActive] = useState("active");

  const [intervalId, setIntervalId] = useState(null);
  const [moveSlide, setMoveSlide] = useState(0);
  const elementRef = useRef(null);
  const [elementWidth, setElementWidth] = useState(null);
  const [windowWidth, setWindowWidth] = useState(0);

  const slides = [
    {
      title: "Biała Podlaska",
      subtitle: "Biuro i Salon JB Multimedia",
      street: "ul. Brzeska 107 A",
      address: "21-500 Biała Podlaska",
      phone: "+48 83 344 70 00",
      fax: "+48 83 344 70 01",
      email: "marketing@jbmultimedia.pl",
      mapSrc:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2454.2617089604073!2d23.145866976969558!3d52.0385462717628!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4721912af90b78bd%3A0x5aa74295570f6cb3!2sBrzeska%20107a%2C%2021-500%20Bia%C5%82a%20Podlaska!5e0!3m2!1spl!2spl!4v1688566967369!5m2!1spl!2spl",
      imgSrc: require("../assets/images/showrooms/firm_color_jpg.jpg").default,
    },
    // {
    //   title: "Przemyśl",
    //   subtitle: "Salon sprzedaży",
    //   street: "ul. Mikołaja Kopernika 11",
    //   address: "37-700 Przemyśl",
    //   phone: "+48 833 446 298",
    //   fax: "+48 83 344 70 01",
    //   email: "przemysl@jbmultimedia.pl",
    //   mapSrc:
    //     "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2576.0073699292907!2d22.774656076851546!3d49.78592633498753!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473b7f20d3eaabc5%3A0xea78f9c002c9e6eb!2sMiko%C5%82aja%20Kopernika%2011%2C%2037-700%20Przemy%C5%9Bl!5e0!3m2!1spl!2spl!4v1696338932560!5m2!1spl!2spl",
    //   imgSrc: require("../assets/images/showrooms/Showroom-przemysl.webp")
    //     .default,
    // },
    // {
    //   title: "Chełm",
    //   subtitle: "Salon sprzedaży",
    //   street: "ul. Lwowska 24",
    //   address: "22-100 Chełm",
    //   phone: "+48 699 660 800",
    //   fax: "+48 83 344 70 01",
    //   mapSrc:
    //     "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2503.985866029662!2d23.465490376912545!3d51.12716873840888!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47239bb28e2f7f55%3A0x3c492f53cc70d596!2sLwowska%2024%2C%2022-100%20Che%C5%82m!5e0!3m2!1spl!2spl!4v1696339111751!5m2!1spl!2spl",
    //   email: "chełm@jbmultimedia.pl",
    //   imgSrc: require("../assets/images/showrooms/Showroom-chelm.webp").default,
    // },
  ];

  const handleNextSlide = () => {
    setMoveSlide((prevSlide) => {
      if (prevSlide >= -(elementWidth / 2) * slides.length) {
        return prevSlide - (elementWidth + 100);
      } else {
        setMoveSlide(0);
      }
    });
  };

  const handlePrevSlide = () => {
    setMoveSlide((prevSlide) => {
      if (prevSlide < -(elementWidth - 100)) {
        return prevSlide + (elementWidth + 100);
      } else {
        setMoveSlide(-(elementWidth - 450) * slides.length);
      }
    });
  };

  useEffect(() => {
    const newWidth = window.innerWidth;
    if (elementRef.current) {
      const width = elementRef?.current?.offsetWidth;
      setElementWidth(width);
    }
    const updateWidnowDimesions = () => {
      setWindowWidth(newWidth);

      if (window.innerWidth !== newWidth) {
        setMoveSlide(0);
      }

      const width = elementRef?.current?.offsetWidth;
      setElementWidth(width);
    };
    window.addEventListener("resize", updateWidnowDimesions);

    window.addEventListener("load", function () {
      return setActive("active");
    });
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <div className={`showrooms-page-info ${active}`}>
        <PageInfo
          header={pageData.header}
          mainTitle={pageData.mainTitle}
          subtitle={pageData.subtitle}
        />
      </div>

      <Layout>
        {/* <div className="layoutWrapper"> */}
        <section className="showrooms-page elements-list">
          <div className="showrooms-page-container">
            {slides.map((slide, index) => (
              <div
                className="showrooms-page-container-element"
                key={index}
                style={{ transform: `translateX(${moveSlide}px)` }}
                ref={elementRef}
              >
                <div className="showrooms-page-txt-container">
                  <div className="txt-container">
                    <div className="txt-wrapper">
                      <div>
                        <h2>{slides[index].title}</h2>
                      </div>
                      <div>
                        <h4>{slides[index].subtitle}</h4>
                        <p>{slides[index].street}</p>
                        <p>{slides[index].address}</p>
                      </div>
                      <div>
                        <p>tel: {slides[index].phone}</p>
                        <p>fax: {slides[index].fax}</p>
                      </div>
                      <div>
                        <p>e-mail: {slides[index].email}</p>
                        <p>skype: info-jbmultimedia</p>
                      </div>
                      <div>
                        <p>NIP: 537-123-42-90</p>
                        <p>REGON: 030156724</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="showrooms-img-container">
                  <img
                    style={{ zIndex: "1" }}
                    className="desktopImg"
                    src={slides[index].imgSrc}
                  />
                </div>
                <iframe
                  src={slides[index].mapSrc}
                  width="600"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            ))}
          </div>
          {/* <div className="slider-nav">
            <button
              onClick={handlePrevSlide}
              className="slider-button slider-button-prev"
            ></button>
            <button
              onClick={handleNextSlide}
              className="slider-button slider-button-next"
            ></button>
          </div> */}
        </section>
        <div className="footer-container-wrapper-form fullImg">
          <div className="left-col">
            {" "}
            <StaticImage
              style={{ zIndex: "1" }}
              className="desktopImg"
              src="../assets/images/footer.jpg"
            />
          </div>
          <div className="footer-txt-container">
            <Link to="/kontakt">
              <h2 className="txtSpecial">
                {" "}
                Porozmawiajmy o <b>Twoich potrzebach</b>
              </h2>
            </Link>
            <span>
              Cieszymy się, że jesteś zainteresowany współpracą z nami. Chętnie
              odpowiemy na Twoje pytania, udzielimy informacji lub omówimy Twoje
              potrzeby biznesowe.
            </span>
            <ContactForm />
          </div>
        </div>
      </Layout>
    </>
  );
};

export const Head = () => (
  <Seo
    title="Salony sprzedaży bezpośredniej"
    description="Zapraszamy do odwiedzenia naszych sklepów stacjonarnych"
  />
);

export default ShowroomsPage;
